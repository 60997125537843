<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <b-modal
      ref="my-modalContatoPage"
      hide-footer
      centered
      id="modal-contato"
      title="Contato"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>Email: goantonio80@gmail.com</h5>
          <h5>Celular: (84) 99644-8111</h5>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="my-modalEquipePage"
      hide-footer
      centered
      id="modal-equipe"
      title="Equipe"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>UFERSA E DUNNAS technology - 2020</h5>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="my-modalTermosPage"
      hide-footer
      centered
      id="modal-termos"
      title="Termos"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>Nenhum termo ainda foi definido</h5>
        </div>
      </div>
    </b-modal>
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          2020 &nbsp;&copy;&nbsp;
        </span>
        Dunnas tecnologia & UFERSA
      </div>

      <div class="nav nav-dark">
        <a v-b-modal.modal-termos class="nav-link pr-3 pl-0"> Termos </a>
        <a v-b-modal.modal-equipe class="nav-link px-3"> Equipe </a>
        <a v-b-modal.modal-contato class="nav-link pl-3 pr-0"> Contato </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
