<template>
  <div>
    <div v-if="tipoUsuario == 0">
      <ul class="menu-nav">
        <li style="margin-top: -0.1rem" class="menu-section">
          <h4 class="menu-text">Recepção</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/PainelPacientes"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-user"></i>
              <span class="menu-text">Pacientes</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/ListaAgendamentos"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-calendar-3"></i>
              <span class="menu-text">Agendamentos</span>
            </a>
          </li>
        </router-link>

        <li class="menu-section">
          <h4 class="menu-text">Triagem</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/FilaDeAtendimento"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-list-2"></i>
              <span class="menu-text">Fila de atendimento</span>
            </a>
          </li>
        </router-link>

        <li class="menu-section">
          <h4 class="menu-text">Consulta</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/FilaAtendimentoConsulta"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-heart-rate-monitor"></i>
              <span class="menu-text">Fila de atendimento</span>
            </a>
          </li>
        </router-link>

        <li class="menu-section">
          <h4 class="menu-text">Gerenciamento</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/painelContasUsuarios"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-group"></i>
              <span class="menu-text">Gerenciamento de contas</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <div v-else-if="tipoUsuario == 1">
      <ul class="menu-nav">
        <li style="margin-top: -0.1rem" class="menu-section">
          <h4 class="menu-text">Recepção</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/PainelPacientes"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-user"></i>
              <span class="menu-text">Pacientes</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/ListaAgendamentos"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-calendar-3"></i>
              <span class="menu-text">Agendamentos</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <div v-else-if="tipoUsuario == 2">
      <ul class="menu-nav">
        <li style="margin-top: -0.1rem" class="menu-section ">
          <h4 class="menu-text">Recepção</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/PainelPacientes"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-user"></i>
              <span class="menu-text">Pacientes</span>
            </a>
          </li>
        </router-link>

        <li class="menu-section">
          <h4 class="menu-text">Triagem</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/FilaDeAtendimento"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-list-2"></i>
              <span class="menu-text">Fila de atendimento</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <div v-else-if="tipoUsuario == 3">
      <ul class="menu-nav">
        <li style="margin-top: -0.1rem" class="menu-section">
          <h4 class="menu-text">Recepção</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/PainelPacientes"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-user"></i>
              <span class="menu-text">Pacientes</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/ListaAgendamentos"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-calendar-3"></i>
              <span class="menu-text">Agendamentos</span>
            </a>
          </li>
        </router-link>

        <li class="menu-section">
          <h4 class="menu-text">Consulta</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/custom-wizard/wizard')
          }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon2-heart-rate-monitor"></i>
            <span class="menu-text">Consultas</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text">Wizard</span>
                </span>
              </li>

              <router-link
                to="/FilaAtendimentoConsulta"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Fila de atendimento</span>
                  </a>
                </li>
              </router-link>

              <router-link
                to="/FilaConsultasProvisorias"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Fila de consulta provisória</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <div v-else-if="tipoUsuario == 4">
      <ul class="menu-nav">
        <li style="margin-top: -0.1rem" class="menu-section">
          <h4 class="menu-text">Triagem</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/FilaDeAtendimento"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-list-2"></i>
              <span class="menu-text">Fila de atendimento</span>
            </a>
          </li>
        </router-link>

        <li class="menu-section">
          <h4 class="menu-text">Consulta</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/FilaAtendimentoConsulta"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon2-heart-rate-monitor"></i>
              <span class="menu-text">Fila de atendimento</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "KTMenu",
  created() {
    var tipo = localStorage.getItem("tipoUsuario");

    if (tipo == "Recepcionista") {
      this.tipoUsuario = 1;
    } else if (tipo == "Enfermeiro") {
      this.tipoUsuario = 2;
    } else if (tipo == "Administrador") {
      this.tipoUsuario = 0;
    } else if (tipo == "Medico") {
      this.tipoUsuario = 3;
    } else if (tipo == "Aluno") {
      this.tipoUsuario = 4;
    }
  },

  data() {
    return {
      tipoUsuario: ""
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
